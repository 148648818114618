<template>
  <b-card
      v-if="localAuthorityData!==null" sub-title="Biodiversité et pratiques de jardinage"
      title="Informations du territoire">
    <b-row class="justify-content-center align-items-center">
      <b-col class="col-6 col-md-4 col-lg-3 col-xl-2 text-center p-1">
        <div class="font-large-1 text-primary font-weight-bolder">{{ localAuthorityData.subscribers.count }}<br></div>
        <div class="font-medium-2 font-weight-bold"> Utilisateurs <br>abonnés</div>
      </b-col>
      <b-col class="col-6 col-md-4 col-lg-3 col-xl-2 text-center p-1">
        <div class="font-large-1 text-primary font-weight-bolder">{{ localAuthorityData.gardens.total }}<br></div>
        <p class="font-medium-2 font-weight-bold"> Jardins <br> crées</p>
      </b-col>
      <b-col class="col-6 col-md-4 col-lg-3 col-xl-2 text-center p-1">
        <div class="font-large-1 text-primary font-weight-bolder">{{ localAuthorityData.biodiv_quiz.count }}<br></div>
        <div class="font-medium-2 font-weight-bold"> Réponses <br> au quizz</div>
      </b-col>
      <b-col class="col-6 col-md-4 col-lg-3 col-xl-2 text-center p-1">
        <div class="font-large-1 text-primary font-weight-bolder">{{ localAuthorityData.gardens.vgardens_total }}<br></div>
        <div class="font-medium-2 font-weight-bold"> Planches <br> potagères</div>
      </b-col>
      <b-col class="col-6 col-md-4 col-lg-3 col-xl-2 text-center p-1">
        <div class="font-large-1 text-primary font-weight-bolder">{{ localAuthorityData.gardens.balcony }}<br></div>
        <div class="font-medium-2 font-weight-bold"> Jardins sur<br> un balcon</div>
      </b-col>
      <b-col class="col-6 col-md-4 col-lg-3 col-xl-2 text-center p-1">
        <div class="font-large-1 text-primary font-weight-bolder">{{ localAuthorityData.gardens.trees }}<br></div>
        <div class="font-medium-2 font-weight-bold"> Arbres <br> déclarés</div>
      </b-col>

      <b-col class="col-12">
        <b-row class=" justify-content-center">
          <b-col
              v-if="localAuthorityData.biodiv_quiz.count>0"
              class="col-12 col-lg-3 pt-2 d-flex justify-content-center align-items-center">
            <vue-apex-charts :options="averageBiodiversityScoreRadialBar.chartOptions"
                :series="scoreToSeries(localAuthorityData.biodiv_quiz.score.avg)"
                height=220
                type="radialBar"/>
          </b-col>

          <b-col class="col-12 col-lg-auto pt-2">

            <b-row class="font-small-3">
              <b-col class="col-12 pt-2 d-flex flex-column align-items-center p-0">
                <jaya-divider class="col-12 col-md-9 col-xl-6 font-medium-2 font-weight-bold p-0" color="primary"
                    text="Origines des plantes"/>
              </b-col>

              <b-col class="col-12 col-md-6 pt-1">
                <p class="mb-25 text-center"><strong>Jardins avec des espèces indigènes</strong>
                  ({{
                    Math.round(100 *
                        localAuthorityData.native_plant.nb_garden / localAuthorityData.gardens.total)
                  }} %)</p>
                <b-progress :value="Math.round(100 *
                    localAuthorityData.native_plant.nb_garden/localAuthorityData.gardens.total)"
                    max="100"
                    style="height:8px"
                    variant="success"/>
              </b-col>

              <b-col class="col-12 col-md-6 pt-1">
                <p class="mb-25 text-center"><strong>Jardins avec des espèces invasives</strong>
                  ({{
                    Math.round(100 *
                        localAuthorityData.invasive_plant.nb_garden / localAuthorityData.gardens.total)
                  }} %)</p>
                <b-progress :value="Math.round(100 *
                    localAuthorityData.invasive_plant.nb_garden/localAuthorityData.gardens.total)"
                    max="100"
                    style="height:8px"
                    variant="danger"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>

      <b-col class="d-flex col-12 justify-content-center pt-3">
        <b-button class="col-12 col-lg-6"
            variant="primary"
            @click="$router.push('/local-authorities/admin/data')">
          <feather-icon icon="BarChartIcon"/>
          Visualiser tous les indicateurs
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import JayaTitle from '@/components/global/JayaTitle.vue'
import VueApexCharts from 'vue-apexcharts'
import {BButton, BCard, BCol, BModal, BProgress, BRow,} from "bootstrap-vue";

export default {
  name: 'LocalAuthorityDataCard',
  components: {
    JayaTitle,
    VueApexCharts,
    BCard,
    BModal,
    BRow,
    BCol,
    BButton,
    BProgress,
  },
  data() {
    return {
      localAuthorityData: null,
      averageBiodiversityScoreRadialBar: {
        chartOptions: {
          labels: [['Indicateur de', 'Biodiversité']],
          plotOptions: {
            radialBar: {
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '70%',
              },
              dataLabels: {
                name: {
                  offsetY: 20,
                  show: true,
                },
                value: {
                  offsetY: -20,
                  color: '#99a2ac',
                  fontSize: '3.0rem'
                }
              }
            }
          },
          colors: ['var(--primary)'],
          stroke: {
            lineCap: 'round'
          }
        }
      },
      subscriptionsSince: 0
    }
  },
  created() {
    this.$store.dispatch("localAuthority/getStats", this.$store.state.localAuthority.memberOf.id)
        .then(response => {
          this.localAuthorityData = response.data[0]
        })
        .catch(() => {
          this.localAuthorityData = null
          this.$notify({
            "title": "Erreur",
            "text": "Une erreur est survenue lors de la récupération des statistiques de votre collectivité, veuillez réessayer ultérieurement",
            "color": "danger",
            "time": 4000
          })
        })
  },
  methods: {
    scoreToSeries(score) {
      score = score * 100
      score = score.toPrecision(2)
      return [score]
    },
  }
}
</script>
